<template>
  <div class="creator-become-steps">
    <div class="creator-steps">
      <VerifyUserStepsCard step="step1" :label="`${$locale.words['step']} 1`" icon="user" />
      <VerifyUserStepsCard step="step2" :label="`${$locale.words['step']} 2`" icon="file_certificate" />
      <VerifyUserStepsCard step="step3" :label="`${$locale.words['step']} 3`" icon="user_check" />
      <VerifyUserStepsCard step="step4" :label="`${$locale.words['step']} 4`" icon="mf_check" class="result" />
    </div>
    <div class="creator-steps-step">
      <transition name="fade-multiple">
        <VerifyUserSteps1 v-if="apply === 'step1'" />
        <VerifyUserSteps2 v-else-if="apply === 'step2'" />
        <VerifyUserSteps3 v-else-if="apply === 'step3'" />
        <VerifyUserSteps4 v-else-if="apply === 'step4'" />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    VerifyUserStepsCard: () => import("./VerifyUserStepsCard.vue"),
    VerifyUserSteps1: () => import("./VerifyUserSteps1.vue"),
    VerifyUserSteps2: () => import("./VerifyUserSteps2.vue"),
    VerifyUserSteps3: () => import("./VerifyUserSteps3.vue"),
    VerifyUserSteps4: () => import("./VerifyUserSteps4.vue"),
  },
  computed: {
    apply: function() {
      return this.$route.query.apply;
    },
  },
};
</script>
